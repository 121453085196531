.addproduct-add-form{
position: fixed;
top:200px;
left:500px;
width:700px;
height:500px;
background-color: white;
opacity:1;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index:88;
box-shadow: 0 0 6px grey;
border-radius: 10px;
}

.add-input{
display: flex;
justify-content:  flex-start;
align-items: center;
margin-bottom: 20px;
width:90%;
}

.add-input label{
width:30%;
}

.add-input input{
width:50%;
}