.app-products-sidebar{
width:50px;
background-color: rgb(6, 6, 69);
color:white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: fixed;
top:100px;
left:0;
}
.side-icons{
margin:10px 0;
}

.side-close{
position: relative;
right: -15px;
}