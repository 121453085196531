.app{
display: flex;
flex-direction: column;
width: 100%;
}

button{
padding:5px 10px;
color:rgb(250, 112, 0);
border-radius: 5px;
outline: none;
border: 1px solid rgb(250, 112, 0);
margin-top: 10px;
}

button:hover{
background-color:rgb(250, 112, 0);
color:white;
}